import { useContext, useMemo, useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Image,
  SortOption,
  SORT_OPTIONS,
  Switch,
  Table,
  Select,
  useQueryParams,
  useEffectSkipFirst,
} from '@faxi/web-component-library';
import dayjs from 'dayjs';
import classNames from 'classnames';

import Icon, { INameExtended } from 'components/Icon';

import useOptions from './useOptions';
import { UserContext, ReportsContext } from 'store';
import LeaderboardModal from './components/LeaderboardModal';

import { LeaderboardData } from 'models';
import { useTablePagination } from 'hooks';
import { apiReports, LeaderboardType } from 'modules';

import config from 'config';

import { NoData } from 'Global.styles';
import * as Styled from './Leaderboard.styles';

export type LeaderboardTab = {
  label: string;
  noDataLabel: string;
  type: LeaderboardType;
  icon?: INameExtended;
};

const LEADERBOARD_USERS_PER_PAGE = 5;

const INITIAL_LEADERBOARD_SORT = {
  sortBy: 'balance',
  sortDirection: SORT_OPTIONS.DSC,
} as SortOption<LeaderboardData>;

const Leaderboard = (props: {
  directions: string;
  selectState?: boolean;
  onActivatePoints?: (value: boolean) => void;
}) => {
  const { directions, selectState, onActivatePoints } = props;

  const {
    communityId,
    userReady,
    userPreferences: { unit },
  } = useContext(UserContext);

  const { t } = useTranslation();

  const { setMultipleQueryParams, removeQueryParams } = useQueryParams<{
    from: string;
    to: string;
    directions: string;
    filterId: string;
  }>();

  const {
    activeNumberOfPoints,
    activeLeaderBoardType: activeTab,
    dateRange,
    depotFilters,
    setDepotFilters,
    setActiveNumberOfPoints,
    applyDefaultDateParams,
    setActiveLeaderBoardType: setActiveTab,
  } = useContext(ReportsContext);

  const buttonRef = useRef<HTMLButtonElement>(null);

  const translationKeys = useMemo(
    () =>
      ({
        position: t('position_leaderboard'),
        name: t('global-name'),
        pdist: t('kilometers_saved'),
        avg_passengers: t('average_passengers'),
        jc: t('number_of_journeys'),
        balance: t('leaderboard-title_number_of_points'),
      } as Record<Partial<keyof LeaderboardData>, string>),
    [t]
  );

  const [showWholeListModal, setShowWholeListModal] = useState(false);

  const itemsKey = useMemo(() => ['leaguetable', 'leaguetable-test'], []);

  const { finalOptions } = useOptions({
    pointsActive: activeNumberOfPoints,
  });

  const {
    data,
    count,
    search,
    loading,
    totalPages,
    totalCount,
    currentPage,
    activeColumnSort,
    setCount,
    onSearchChange,
    setCurrentPage,
    setActiveColumnSort,
  } = useTablePagination<LeaderboardData, 'leaguetable'>({
    itemsKey,
    totalKey: ['total', 'total-test'],
    spinnerSelector: showWholeListModal
      ? '.leaderboard-modal__body'
      : '.leaderboard-container',
    condition: !!(communityId && dateRange && userReady),
    initialSortBy: INITIAL_LEADERBOARD_SORT.sortBy,
    initialSortDirection: INITIAL_LEADERBOARD_SORT.sortDirection,
    deps: [directions, communityId, showWholeListModal, activeNumberOfPoints],
    resetDeps: [activeTab, communityId, dateRange],
    count: LEADERBOARD_USERS_PER_PAGE,
    customErrorMessage: t('no_members_in_community'),
    applyQueryParams: false,
    onDataLoad: (data) => {
      setDepotFilters(data.filters);
    },
    mappingFunction: async (data: LeaderboardData[]) => {
      return data?.map(
        ({
          position,
          image_url,
          first_name,
          last_name,
          pdist,
          jc,
          istest,
          avg_passengers,
          screen_name,
          balance,
        }) => ({
          id: `${activeTab}_${screen_name}`,
          position: (
            <span className={classNames({ 'kinto-test-data': istest })}>
              {position}
            </span>
          ),
          name: (
            <div className="table-data-with-image">
              <Image
                className="profile-img"
                src={image_url || ''}
                fallbackUrl="/assets/svg/user_circle_placeholder.svg"
                alt={t('user_profile_picture', { user: first_name })}
              />
              <span className={classNames({ 'kinto-test-data': istest })}>
                {first_name || last_name
                  ? [first_name, last_name].join(' ').trim()
                  : '-'}
              </span>
            </div>
          ),
          pdist: (
            <span className={classNames({ 'kinto-test-data': istest })}>
              {pdist}
            </span>
          ),
          avg_passengers: (
            <span className={classNames({ 'kinto-test-data': istest })}>
              {avg_passengers || '-'}
            </span>
          ),
          jc: (
            <span className={classNames({ 'kinto-test-data': istest })}>
              {jc || '0'}
            </span>
          ),
          balance: (
            <span className={classNames({ 'kinto-test-data': istest })}>
              {balance || '0'}
            </span>
          ),
        })
      ) as any;
    },
    apiRequest: (
      count: number,
      offset: number,
      search: string,
      sort_by,
      sort_direction,
      cancelConfig
    ): any =>
      apiReports.leaguetableReport({
        oid: communityId!,
        unit,
        count,
        offset,
        search,
        directions,
        type: activeNumberOfPoints ? 'point' : activeTab,
        ...(!Number(activeTab) && activeTab === 'point'
          ? { period: 'total' }
          : {
              from: dateRange?.from.format(config.apiDateFormat)!,
              to: dateRange?.to.format(config.apiDateFormat)!,
            }),
        config: cancelConfig,
        sort_by,
        sort_direction,
      }),
  });

  const handleOnColumnSort = useCallback(
    (sort: SortOption<LeaderboardData>) => {
      const { sortBy, sortDirection } = sort;
      setActiveColumnSort(sortBy, sortDirection);
    },
    [setActiveColumnSort]
  );

  useEffectSkipFirst(() => {
    setActiveTab('all');
    setActiveNumberOfPoints(false);
    setTimeout(() => removeQueryParams(['filterId', 'directions']), 0);
  }, [communityId, setActiveTab, setActiveNumberOfPoints]);

  return (
    <Styled.Container className="leaderboard">
      <div className="leaderboard__header">
        <Select
          value={activeTab}
          options={finalOptions}
          onChange={(opt) => {
            setActiveTab(opt.value as LeaderboardType);

            if (opt.value === 'point') {
              removeQueryParams(['filterId']);
              return;
            }

            if (Number(opt.value)) {
              const filter = depotFilters?.find(
                (filter) => filter.id === +opt.value
              );

              const range = {
                to: dayjs(filter?.data.to).format(config.apiDateFormat),
                from: dayjs(filter?.data.from).format(config.apiDateFormat),
              };

              setMultipleQueryParams({
                ...range,
                filterId: filter?.id,
              });
            }
          }}
        />

        <Switch
          className="leaderboard__header__switch"
          value={activeNumberOfPoints}
          onChange={async (ev) => {
            setActiveTab(ev.target.value === 'true' ? 'all' : 'point');
            onActivatePoints?.(ev.target.value === 'true' ? false : true);
            setActiveNumberOfPoints((old) => !old);

            if (ev.target.value === 'true') {
              applyDefaultDateParams();

              setMultipleQueryParams({
                filterId: null,
                to: dayjs().format(config.apiDateFormat),
                from: dayjs().subtract(29, 'day').format(config.apiDateFormat),
              });
            }
          }}
          label={t('leaderboard-title_number_of_points')}
        />
      </div>

      {/* TABLE */}
      <Table<LeaderboardData>
        tableData={data.slice(0, 5)}
        tableId="leaderboard-table"
        noDataPlaceholder={
          <NoData className="kinto-no-data">
            {t('search_no_results_found')}
          </NoData>
        }
        translationKeys={translationKeys}
        excludeSortColumns={[
          'name',
          'jc',
          'position',
          'pdist',
          'avg_passengers',
        ]}
        initialSort={activeColumnSort as SortOption<LeaderboardData>}
        pageSelectorAriaLeftLabel={t('accessibility-button_previous_page')}
        pageSelectorAriaRightLabel={t('accessibility-button_next_page')}
        onColumnSortClicked={handleOnColumnSort}
        // TODO: investigate why this can not be const memo and then passed to LeaderboardModal
        // this is duplicated in LeaderboardModal
        excludeColumns={
          [
            'id',
            'last_name',
            'pc',
            'screen_name',
            'distance_saved',
            'tspic',
            'upc',
            'user_id',
          ].concat(
            activeTab === 'driving' ? [] : ['avg_passengers'],
            activeTab !== 'all' ? [] : ['pdist'],
            activeTab === 'point' || !!Number(activeTab)
              ? ['pdist', 'jc']
              : ['balance']
          ) as (keyof LeaderboardData)[]
        }
      />

      {/* VIEW WHOLE LIST */}
      {!selectState && (
        <Button
          ref={buttonRef}
          className="leaderboard__whole-list-button"
          type="button"
          variant="ghost"
          onClick={() => {
            setShowWholeListModal(true);
          }}
          icon={<Icon name="chevron-right" />}
          iconPosition="right"
        >
          {t('view_whole_list')}
        </Button>
      )}

      {/* MODAL */}
      {showWholeListModal && (
        <LeaderboardModal
          activeTab={activeTab}
          data={data}
          setActiveTab={setActiveTab}
          activeNumberOfPoints={activeNumberOfPoints}
          setActiveNumberOfPoints={setActiveNumberOfPoints}
          activeColumnSort={activeColumnSort}
          handleOnColumnSort={handleOnColumnSort}
          translationKeys={translationKeys}
          search={search}
          onSearchChange={onSearchChange}
          count={count}
          totalPages={totalPages}
          totalCount={totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          updateQueryParams={setMultipleQueryParams}
          loading={loading}
          setCount={setCount}
          onClose={() => {
            setShowWholeListModal(false);
            if (search) {
              onSearchChange('');
            }
            buttonRef.current?.focus();
          }}
        />
      )}
    </Styled.Container>
  );
};

export default Leaderboard;
