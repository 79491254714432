import {
  createContext,
  Dispatch,
  MutableRefObject,
  SetStateAction,
} from 'react';
import {
  ChartDataTotals,
  ChartDataWithTest,
  DepotFilter,
  KpiData,
} from 'models';
import { DatePickerValue } from '@faxi/web-component-library';
import { LeaderboardType } from 'modules';

interface ReportsContext {
  dateRange?: DatePickerValue;
  rangeStats?: {
    messagesCount: ChartDataWithTest;
    registeredUsers: ChartDataWithTest;
  };
  rangeStatsReportError: boolean;
  kpiData?: KpiData;

  activeUsersTotal: ChartDataTotals;

  distancesReportError: boolean;

  loadingDistances: boolean;

  activeLeaderBoardType: LeaderboardType;
  activeNumberOfPoints: boolean;
  depotFilters: DepotFilter[];

  statsByRangeReport: (
    organisationId: number,
    startDate: string,
    endDate: string,
    unit: string
  ) => void;
  updateDirections: (directions: string) => void;
  updateDateRange: (range: DatePickerValue) => void;
  setDepotFilters: Dispatch<SetStateAction<DepotFilter[]>>;
  setActiveNumberOfPoints: Dispatch<SetStateAction<boolean>>;
  setActiveLeaderBoardType: Dispatch<SetStateAction<LeaderboardType>>;
  applyDefaultDateParams: () => void;
  convertReportsDataToArrays: (
    numberOfPointsIsActive: boolean
  ) => Promise<string[][] | undefined>;
  totalNumberOfDrivers: MutableRefObject<{ total: number; totalTest: number }>;
  totalNumberOfWalkers: MutableRefObject<{ total: number; totalTest: number }>;
  totalNumberOfCyclers: MutableRefObject<{ total: number; totalTest: number }>;
  totalNumberOfPassengers: MutableRefObject<{
    total: number;
    totalTest: number;
  }>;

  numberOfMessagesTotal: number;
  numberOfTestMessagesTotal: number;
}

export const reportsDefaultValue: ReportsContext = {
  rangeStats: undefined,
  rangeStatsReportError: false,
  distancesReportError: false,
  loadingDistances: false,
  depotFilters: [],
  activeLeaderBoardType: 'all',
  activeNumberOfPoints: false,

  statsByRangeReport: () => {},
  updateDirections: () => {},
  updateDateRange: () => {},
  setDepotFilters: () => {},
  applyDefaultDateParams: () => {},
  setActiveNumberOfPoints: () => {},
  setActiveLeaderBoardType: () => {},
  convertReportsDataToArrays: () => new Promise(() => {}),

  activeUsersTotal: { total: 0, testTotal: 0 },
  totalNumberOfDrivers: { current: { total: 0, totalTest: 0 } },
  totalNumberOfWalkers: { current: { total: 0, totalTest: 0 } },
  totalNumberOfCyclers: { current: { total: 0, totalTest: 0 } },
  totalNumberOfPassengers: { current: { total: 0, totalTest: 0 } },

  numberOfMessagesTotal: 0,
  numberOfTestMessagesTotal: 0,
};

const ReportsContext = createContext<ReportsContext>(reportsDefaultValue);

export default ReportsContext;
