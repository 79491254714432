import {
  Event,
  APIPaginatedResponse,
  APIResponseCommon,
  APIResponseCustom,
} from 'models';
import httpClient from '../httpClient';

export type CreateEventData = Pick<
  Event,
  | 'creator_id'
  | 'description'
  | 'start_date'
  | 'start_time'
  | 'end_date'
  | 'end_time'
  | 'type'
> & {
  data: {
    title: string;
    lat: number;
    lng: number;
    address: string;
    city?: string;
    country?: string;
    organisation_id: number;
  };
};

export type UpdateEventData = Omit<CreateEventData, 'creator_id' | 'data'> &
  Partial<{
    data: Partial<{
      title: string;
      lat: number;
      lng: number;
      address: string;
      city?: string;
      country?: string;
      organisation_id: number;
    }>;
  }>;

const getAll = async ({
  communityId,
  offset,
  count,
  search,
}: {
  communityId: number;
  offset?: number;
  count?: number;
  search?: string;
}) =>
  httpClient
    .get<APIPaginatedResponse<{ appointments: Event[] }>>(
      `appointments/organisation/${communityId}`,
      {
        params: {
          type: 'event-appointment',
          appointment_status: 'upcoming',
          offset,
          count,
          ...(search && { title: search }),
        },
      }
    )
    .then((res) => res.data);

const create = async (data: CreateEventData) =>
  httpClient
    .post<APIResponseCustom<{ appointment: Event }>>('appointments', data)
    .then((res) => res.data);

const update = async (id: number, data: UpdateEventData) =>
  httpClient
    .post<APIResponseCustom<{ appointment: Event }>>(
      `appointments/${id}`,
      data,
      {
        params: { _method: 'PUT' },
      }
    )
    .then((res) => res.data);

const remove = async (id: number) =>
  httpClient
    .post<APIResponseCommon>(`appointments/${id}`, null, {
      params: { _method: 'DELETE' },
    })
    .then((res) => res.data);

export default {
  getAll,
  create,
  update,
  remove,
};
