import styled, { css } from 'styled-components';
import {
  flex,
  fontSize,
  position,
  aboveTablet,
  phablet,
  pxToRem,
} from '@faxi/web-css-utilities';

import { elevate_xxl, theme } from '@faxi/web-component-library';
import { size } from 'lodash';

////////////////////// BOX SHADOW STYLES ////////////////////////////

export const hideAbovePhablet = css`
  ${aboveTablet(css`
    display: none;
  `)}
`;

export const hideAboveTablet = css`
  @media (min-width: 768px) {
    display: none;
  }
`;

export const hideBelowPhablet = css`
  ${phablet(css`
    display: none;
  `)}
`;

export const LoadingOverlay = styled.div`
  ${position('fixed', '0')};
  ${flex('column', 'center', 'center')};
`;

export const NoData = styled.p`
  ${fontSize(theme.sizes.SIZE_16, '22px')};

  font-weight: 600;
  color: var(--SHADE_1_2);
  margin: ${theme.sizes.SIZE_24};
`;

export const PanelContainer = styled.div`
  padding: 0 ${theme.sizes.SIZE_48} ${theme.sizes.SIZE_46};

  ${phablet(
    css`
      padding: 0 ${theme.sizes.SIZE_20} ${theme.sizes.SIZE_20};
      width: 100%;
    `
  )};
`;

export const SearchableUsersSection = styled.section`
  display: flex;
  flex-direction: column;
  background-color: var(--BACKGROUND_1_1);

  .kinto-searchable-users-section {
    &__top {
      padding: ${theme.sizes.SIZE_16} ${theme.sizes.SIZE_20} 0;

      h1 {
        margin-top: ${theme.sizes.SIZE_16};
      }
    }

    &__send-to-all {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: ${theme.sizes.SIZE_16} ${theme.sizes.SIZE_20};
      word-break: normal;
      overflow-wrap: anywhere;
      color: var(--SHADE_1_2);

      &--disabled {
        opacity: 0.5;
      }

      &__icon-container {
        ${size(theme.sizes.SIZE_46)};
        ${elevate_xxl};

        border-radius: 50%;
        margin-right: ${theme.sizes.SIZE_20};
        flex: 0 0 ${pxToRem(theme.sizes.SIZE_46)};
      }
    }

    &__search {
      background-color: var(--BACKGROUND_1_1);
      padding: ${theme.sizes.SIZE_20};
    }

    &__navigation {
      width: 100%;
      flex: 1 1 100%;

      ${phablet(css`
        .kinto-tab-navigation__infinite-scroll {
          position: unset;
          inset: auto;
        }
      `)}
    }
  }
`;
