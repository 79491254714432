import styled, { css } from 'styled-components';

import { flex, phablet } from '@faxi/web-css-utilities';
import { theme } from '@faxi/web-component-library';

export const Container = styled.div`
  ${flex('column')};

  flex: 1;

  .leaderboard {
    &__tabs {
      margin: ${theme.sizes.SIZE_12} 0 ${theme.sizes.SIZE_20};

      ${phablet(
        css`
          margin-bottom: unset;
          background-color: red;
        `
      )};

      .wcl-tabs--first-active.wcl-tabs--last-active {
        &::before,
        label {
          border-radius: ${theme.sizes.SIZE_8};
        }
      }
    }

    &__type-select {
      margin: ${theme.sizes.SIZE_16} 0;
    }

    &__whole-list-button {
      margin-top: auto;
    }

    &__header {
      ${flex()};

      flex-wrap: wrap;
      margin: ${theme.sizes.SIZE_12} 0;

      &__switch {
        flex-direction: row;
        margin: auto 0;

        .wcl-switch__label {
          padding: ${theme.sizes.SIZE_8} ${theme.sizes.SIZE_8}
            ${theme.sizes.SIZE_8} ${theme.sizes.SIZE_16};
        }
      }
    }
  }
`;
