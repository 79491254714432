import { theme } from '@faxi/web-component-library';
import { fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const CommunityElement = styled.div`
  color: var(--PRIMARY_1_1);
  ${fontSize(theme.fontSizes.FONT_14, '17px')};
  font-weight: 400;
  width: fit-content;
  word-break: break-word;
`;
