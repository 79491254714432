import { theme } from '@faxi/web-component-library';
import { flex, fontSize, position, size } from '@faxi/web-css-utilities';

import styled from 'styled-components';

export const MessagesWrapper = styled.div`
  ${size('100%')};
  position: relative;
  flex: 1 1 100%;
  overflow: hidden;

  .infinite-scroll {
    ${position('absolute', '0')};
  }
`;

export const MessageBoardStyled = styled.div`
  background-color: var(--BACKGROUND_1_1);
  overflow: auto;
  ${flex('column', 'flex-end', 'initial')};
  height: 100%;
  max-height: 100vh;

  .messages-board {
    &__messages {
      background-color: var(--BACKGROUND_2_1);
      display: flex;
      flex-direction: column-reverse;
      height: 100%;
      width: 100%;
      overflow: auto;
      padding: ${theme.sizes.SIZE_4} 0;

      .kinto-user-message {
        margin-top: ${theme.sizes.SIZE_4};
        margin-bottom: ${theme.sizes.SIZE_24};

        @media (max-width: ${theme.breakAtMaxWidth}px) {
          margin-top: ${theme.sizes.SIZE_12};
          margin-bottom: ${theme.sizes.SIZE_32};
        }
      }

      &__divider {
        ${flex('row', 'center', 'center')};
        color: var(--SHADE_1_1);
        margin-top: -${theme.sizes.SIZE_20};
        padding: ${theme.sizes.SIZE_12} 0;

        &::before,
        &::after {
          content: '';
          flex: 1;
          border-bottom: ${theme.sizes.SIZE_1} solid var(--SHADE_1_7);
        }

        > div {
          border: ${theme.sizes.SIZE_1} solid var(--SHADE_1_7);
          border-radius: ${theme.sizes.SIZE_24};
          padding: ${theme.sizes.SIZE_4} ${theme.sizes.SIZE_12};
          color: var(--SHADE_1_2);
          ${fontSize(theme.fontSizes.FONT_12)};
        }
      }

      &__no-more {
        text-align: center;
        font-weight: 600;
        margin-top: ${theme.sizes.SIZE_20};
        margin-bottom: ${theme.sizes.SIZE_40};
        color: var(--SHADE_1_1);
      }
    }
  }
`;
