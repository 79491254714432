import dayjs from 'dayjs';
import config from 'config';

//Function is created to get UTC offset without DTS (Daylight saving time)
//in order to send information to the server only in winter time mode
const getWinterUtcOffset = () => {
  const winterDate = new Date(Date.UTC(new Date().getFullYear(), 0, 1));
  const utcOffsetMinutes = winterDate.getTimezoneOffset(); // Get the UTC offset in minutes
  return utcOffsetMinutes / 60; // Convert to hours
};

const oppositeNumber = (value: number) =>
  value < 0 ? Math.abs(value) : value > 0 ? -value : 0;

const formattedTime = (start_time: string, end_time: string) => {
  return `${dayjs(start_time, 'hh-mm').format(config.timeFormat)}  -  ${dayjs(
    end_time,
    'hh-mm'
  ).format(config.timeFormat)}`;
};

const dateString = (
  date?: string,
  time?: string,
  format: string = config.dateFormatSimple,
  isUTC = true,
  winter = true
) => {
  return dayjs(`${date}T${time ? time : '00:00'}${isUTC ? 'Z' : ''}`)
    .utcOffset(winter ? oppositeNumber(getWinterUtcOffset()) : '')
    .format(format);
};

const dateStringLocale = (
  date?: string,
  time?: string,
  format: string = config.dateFormatSimple
) => dateString(date, time, format, false, false);

const dateTimeString = (
  date?: string,
  time?: string,
  dateFormat?: string,
  timeFormat?: string,
  timeWrapper?: (value: string) => string
) => {
  return `${dateString(date, time, dateFormat)} ${
    !time
      ? ''
      : timeWrapper
      ? timeWrapper?.(dateString(date, time, timeFormat))
      : dateString(date, time, timeFormat)
  }`;
};

export {
  getWinterUtcOffset,
  dateString,
  dateStringLocale,
  dateTimeString,
  formattedTime,
};
