import { Modal, theme } from '@faxi/web-component-library';
import { phablet, aboveMobile, pxToRem } from '@faxi/web-css-utilities';

import styled, { css } from 'styled-components';

export const LeaderboardModal = styled(Modal)`
  .wcl-modal {
    width: fit-content;
  }
  .leaderboard-modal {
    &__body {
      display: flex;
      flex-direction: column;
      width: ${pxToRem(theme.sizes.SIZE_768)};
      padding: ${theme.sizes.SIZE_4} 0;

      ${phablet(
        css`
          width: unset;
          .leaderboard-table__body {
            max-height: unset;
          }
        `
      )};

      &__search {
        margin: ${theme.sizes.SIZE_32} 0;
        width: ${pxToRem(theme.sizes.SIZE_240)};

        ${phablet(css`
          width: 100%;
        `)}
      }

      &__leaderboard-table {
        height: 100%;

        ${aboveMobile(
          css`
            min-height: ${pxToRem('300px')};
          `
        )}

        ${phablet(css`
          height: ${pxToRem(theme.sizes.SIZE_288)};
        `)};
      }

      &__page-selector {
        margin-top: ${theme.sizes.SIZE_46};

        ${phablet(
          css`
            display: none;
          `
        )};
      }
    }
  }
`;
