import Campaigns from 'pages/Settings/Gamification/pages/Campaigns';
import CreateNewCampaign from 'pages/Settings/Gamification/pages/Campaigns/components/CreateNewCampaign';
import EarnedRewards from 'pages/Settings/Gamification/pages/EarnedRewards';
import Gamification from 'pages/Settings/Gamification/pages/Gamification';
import Points from 'pages/Settings/Gamification/pages/Points';
import Rewards from 'pages/Settings/Gamification/pages/Rewards';
import { Outlet } from 'react-router-dom';
import { ExtendRouteObject } from 'router/routes';
import { CampaignProvider } from 'store';
import { appUri } from '../../../../../config';

export default [
  {
    handle: 'gamfication-links',
    path: '',
    element: <Gamification />,
  },
  {
    handle: 'rewards',
    path: appUri.GAMIFICATION_REWARDS,
    element: <Rewards />,
  },
  {
    handle: 'earned-rewards',
    path: appUri.GAMIFICATION_EARNED_REWARDS,
    element: <EarnedRewards />,
  },
  {
    handle: 'campaign-list',
    path: appUri.GAMIFICATION_CAMPAIGNS,
    element: (
      <CampaignProvider>
        <Outlet />
      </CampaignProvider>
    ),
    children: [
      {
        handle: 'campaign-list',
        path: '',
        element: <Campaigns />,
      },
      {
        handle: 'create-campaign',
        path: appUri.GAMIFICATION_CAMPAIGNS_CREATE_NEW,
        element: <CreateNewCampaign />,
      },
      {
        handle: 'edit-campaign',
        path: appUri.GAMIFICATION_CAMPAIGNS_EDIT,
        element: <CreateNewCampaign />,
      },
    ],
  },
  {
    handle: 'points',
    element: <Points />,
    path: appUri.GAMIFICATION_POINTS_SYSTEM,
  },
] as ExtendRouteObject[];
