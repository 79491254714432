import { FC } from 'react';
import {
  GoogleAutocomplete,
  GoogleAutocompleteProps,
} from '@faxi/web-component-library';
import { useFieldError, FieldProps } from '@faxi/web-form';
import { AddressPlace } from 'models';
import { useTranslation } from 'react-i18next';

export type GoogleAutocompletePropsValue = AddressPlace & { placeId?: string };

export type GoogleAutocompleteFieldProps = FieldProps<
  GoogleAutocompletePropsValue,
  any
> &
  GoogleAutocompleteProps;

const GoogleAutocompleteField: FC<GoogleAutocompleteFieldProps> = (
  props: GoogleAutocompleteFieldProps
): JSX.Element => {
  const { dirty, touched, error, required, ...rest } = props;

  const { t } = useTranslation();
  const showError = useFieldError(props);

  return (
    <GoogleAutocomplete
      closeOptionsOnClear
      deleteInputAriaLabel={t('delete_input')}
      required={required}
      {...(required && {
        requiredLabel: t('global-input_field_required_label'),
      })}
      chevronBtnAriaLabel={t('accessibility-show_select_options')}
      errorText={String(error)}
      error={showError}
      {...rest}
    />
  );
};

export default GoogleAutocompleteField;
