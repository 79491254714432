import { theme } from '@faxi/web-component-library';
import {
  flex,
  fontSize,
  marginChildren,
  position,
  pxToRem,
  size,
} from '@faxi/web-css-utilities';

import styled from 'styled-components';

export const UserMessageStyled = styled.div`
  ${flex('row', 'flex-end', 'flex-end')};
  font-weight: 500;
  padding: 0 ${theme.sizes.SIZE_20};
  ${marginChildren(`0 ${theme.sizes.SIZE_16} 0 0`)};

  &.kinto-user-message {
    &--left {
      flex-direction: row-reverse;
      ${marginChildren(`0 0 0 ${theme.sizes.SIZE_16}`)};

      .kinto-user-message {
        &__content {
          &__message {
            text-align: left;
          }

          &__cloud-icon {
            transform: rotate(-90deg);
            ${position(
              'absolute',
              `bottom ${pxToRem(theme.sizes.SIZE_16)} left ${pxToRem('-11px')}`
            )};
          }
        }
      }
    }
  }

  .kinto-user-message {
    &__avatar {
      ${flex('row', 'center', 'center')};
      ${size(theme.sizes.SIZE_40)};

      flex: 0 0 ${pxToRem(theme.sizes.SIZE_40)};
    }

    &__content {
      padding: ${`${theme.sizes.SIZE_8} ${theme.sizes.SIZE_12}
        ${theme.sizes.SIZE_2}`};
      background-color: var(--BACKGROUND_1_1);
      border-radius: ${theme.sizes.SIZE_8};
      ${marginChildren(`0 0 ${theme.sizes.SIZE_8} 0`)};
      position: relative;
      border: ${theme.sizes.SIZE_1} solid var(--SHADE_1_7);

      &__message {
        color: var(--SHADE_1_1);
        text-align: right;
        white-space: pre-wrap;
        word-break: normal;
        overflow-wrap: anywhere;
        ${marginChildren(`0 0 ${theme.sizes.SIZE_4} 0`)};

        &__time {
          ${fontSize(theme.fontSizes.FONT_12)};
          color: var(--SHADE_1_2);
        }

        &--failed {
          color: var(--SHADE_1_5);

          .kinto-user-message__content__message__time {
            color: var(--SHADE_1_6);
          }
        }
      }

      &__failed {
        display: flex;
        color: var(--ALERT_ERROR_1_1);
        ${flex('row', 'center', 'center')};
        ${marginChildren(`0 ${theme.sizes.SIZE_12} 0 0`)};
        color: var(--ALERT_ERROR_1_1);

        > * {
          ${fontSize(theme.fontSizes.FONT_12)};
          height: fit-content;
          min-height: unset;
        }
      }

      &__cloud-icon {
        transform: rotate(90deg);
        ${size('14px', theme.sizes.SIZE_8)};
        ${position(
          'absolute',
          `bottom ${pxToRem(theme.sizes.SIZE_16)} right ${pxToRem('-11px')}`
        )};
      }
    }
  }
`;
