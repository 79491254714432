import { SVGProps } from 'react';
import { getColor } from '@faxi/web-component-library';

type IconProps = SVGProps<SVGSVGElement>;

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: IconProps) => (
  <svg
    {...props}
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14.5 8.73"
    fill="currentColor"
  >
    <path
      className="cls-1"
      fill={getColor('--BACKGROUND_1_1')}
      d="M13.893,8.73H.613l5.16-7.37a1.692,1.692,0,0,1,2.95,0Z"
    />
    <path
      className="cls-2"
      fill={getColor('--SHADE_1_7')}
      d="M9.13,1.07A2.327,2.327,0,0,0,7.25,0,2.313,2.313,0,0,0,5.37,1.07L0,8.73H1.22L6.18,1.64a1.222,1.222,0,0,1,2.13,0l4.97,7.09H14.5Z"
    />
  </svg>
);
