import { SetStateAction, memo, Dispatch, useContext } from 'react';
import {
  Button,
  ModalProps,
  Select,
  SelectOption,
  SortDirections,
  SortOption,
  Switch,
  Table,
} from '@faxi/web-component-library';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import config from 'config';
import { InputField } from 'components';
import { ReportsContext } from 'store';
import { LeaderboardData } from 'models';
import { LeaderboardType } from 'modules';
import Icon from 'components/Icon';

import useOptions from '../../useOptions';
import { ViewsPerPage } from 'pages/People/People.page';

import * as Styled from './LeaderboardModal.styles';
import { NoData } from 'Global.styles';

type LeaderboardModalProps = {
  data: LeaderboardData[];
  translationKeys: Record<Partial<keyof LeaderboardData>, string>;
  activeTab: LeaderboardType;
  setActiveTab: (value: SetStateAction<LeaderboardType>) => void;
  search: string;
  onSearchChange: (search: string) => void;
  count: number;
  totalPages: number;
  totalCount: number;
  currentPage: number;
  setCurrentPage: (page: number, skip?: boolean | undefined) => void;
  setCount: (count: number) => void;
  activeNumberOfPoints: boolean;
  setActiveNumberOfPoints: Dispatch<SetStateAction<boolean>>;
  activeColumnSort: {
    sortBy: string;
    sortDirection: SortDirections;
  };
  handleOnColumnSort: (sort: SortOption<LeaderboardData>) => void;
  updateQueryParams: (
    params: Partial<Record<string, string | number | null>>,
    replace?: boolean | undefined,
    hash?: string | undefined
  ) => void;
} & ModalProps;

const LeaderboardModal = (props: LeaderboardModalProps) => {
  const {
    data,
    loading,
    translationKeys,
    activeTab,
    setActiveTab,
    search,
    onSearchChange,
    count,
    totalPages,
    totalCount,
    currentPage,
    setCurrentPage,
    setCount,
    activeNumberOfPoints,
    setActiveNumberOfPoints,
    activeColumnSort,
    handleOnColumnSort,
    updateQueryParams,
    ...rest
  } = props;

  const { t } = useTranslation();

  const { depotFilters } = useContext(ReportsContext);

  const { finalOptions } = useOptions({
    pointsActive: activeNumberOfPoints,
  });

  return (
    <Styled.LeaderboardModal
      {...rest}
      className="leaderboard-modal"
      title={t('leaderboards')}
      ariaCloseModal={t('accessibility-button_close_modal', {
        name: t('leaderboards'),
      })}
    >
      <div className="leaderboard-modal__body">
        <div className="leaderboard__header">
          <Select
            renderAsPortal
            value={activeTab}
            options={finalOptions}
            onChange={(opt) => {
              setActiveTab(opt.value as LeaderboardType);

              if (Number(opt.value)) {
                const filter = depotFilters?.find(
                  (filter) => filter.id === +opt.value
                );

                const range = {
                  to: dayjs(filter?.data.to).format(config.apiDateFormat),
                  from: dayjs(filter?.data.from).format(config.apiDateFormat),
                };

                updateQueryParams({
                  ...range,
                  filterId: filter?.id,
                });
              }
            }}
          />

          <Switch
            name="points_enabled"
            className="leaderboard__header__switch"
            value={activeNumberOfPoints}
            onChange={async (ev) => {
              setActiveTab(ev.target.value === 'true' ? 'all' : 'point');
              setActiveNumberOfPoints((old) => !old);
              if (search) {
                onSearchChange('');
              }
            }}
            label={t('leaderboard-title_number_of_points')}
          />
        </div>

        {!activeNumberOfPoints && (
          <InputField
            className="leaderboard-modal__body__search"
            value={search}
            onChange={onSearchChange}
            prefixIcon={<Icon name="magnifying-glass" />}
            placeholder={t('selgroup_search')}
            {...(search && {
              suffixIcon: (
                <Button
                  variant="ghost"
                  aria-label={t('delete_input')}
                  icon={<Icon name="xmark" />}
                  onClick={() => {
                    onSearchChange('');
                  }}
                />
              ),
            })}
          />
        )}

        <Table<LeaderboardData>
          tableId="leaguetable-table"
          tableData={data}
          loadingData={loading}
          noDataPlaceholder={
            <NoData className="kinto-no-data">
              {t('search_no_results_found')}
            </NoData>
          }
          excludeSortColumns={[
            'name',
            'jc',
            'position',
            'pdist',
            'avg_passengers',
          ]}
          initialSort={activeColumnSort as SortOption<LeaderboardData>}
          perPageLabel={t('per_page')}
          chevronBtnAriaLabel={t('per_page')}
          pageSelectorAriaLeftLabel={t('accessibility-button_previous_page')}
          pageSelectorAriaRightLabel={t('accessibility-button_next_page')}
          perPagePlaceholder={t('per_page')}
          translationKeys={translationKeys}
          onColumnSortClicked={handleOnColumnSort}
          paginationData={{
            limit: count,
            totalPages,
            totalCount,
            currentPage,
          }}
          goToPageInputProps={{ placeholder: t('global-go_to_page') }}
          onPageChange={setCurrentPage}
          onLimitChange={(data: SelectOption) => {
            setCount(+data.value as ViewsPerPage);
          }}
          excludeColumns={
            [
              'id',
              'last_name',
              'pc',
              'screen_name',
              'tspic',
              'upc',
              'user_id',
            ].concat(
              activeTab === 'driving' ? [] : ['avg_passengers'],
              activeTab !== 'all' ? [] : ['pdist'],
              activeTab === 'point' || !!Number(activeTab)
                ? ['pdist', 'jc']
                : ['balance']
            ) as (keyof LeaderboardData)[]
          }
        />
      </div>
    </Styled.LeaderboardModal>
  );
};

export default memo(LeaderboardModal);
