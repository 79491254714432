const appUri = {
  BASE: '/',
  ANY: '**',
  HOME: 'home',
  LOGIN: 'login',
  SIGN_UP: 'sign-up',
  RESEND_EMAIL: 'resend-email',
  LOGIN_RESET_PASSWORD: 'reset-password',
  LOGIN_CHANGE_PASSWORD: 'change-password',
  MICROSOFT_REDIRECTION: 'login-redirection',
  LOGIN_SUCCESSFUL: 'successful',
  LOGIN_DISABLED_COMMUNITY: 'disabled-community',
  NOT_FOUND: 'not-found',
  REDIRECTION: 'admin/redirection',
  COMMUNITY: 'community',
  COMMUNITY_SINGLE: 'community/:organisationId',
  MAP: 'map',
  INTERSTITIAL: 'interstitial',
  ACTIVATE: 'activate',
  ADMIN: 'admin',
  ACCOUNT_DELETION: 'account-deletion',

  SURVEY: 'survey',
  SURVEY_ALREADY_COMPLETED: 'already-completed',
  SURVEY_FINISHED: 'finished',
  SURVEY_SCHEDULED: 'scheduled',

  SURVEY_START: 'start',
  SURVEY_START_COMMUTING_PATTERN: 'commuting-pattern',
  SURVEY_START_HOME_ADDRESS: 'home-address',
  SURVEY_START_DESTINATION_ADDRESS: 'destination-address',
  SURVEY_START_MODE_OF_TRANSPORT: 'mode-of-transport',
  SURVEY_START_VEHICLE_INFORMATION: 'vehicle-information',

  SURVEY_YOUR_EMISSIONS: 'your-emissions',

  ACCOUNT_SETTINGS: 'account-settings',
  ACCOUNT_SETTINGS_UPDATE_PROFILE: 'update-profile',
  ACCOUNT_SETTINGS_UPDATE_ADDRESS: 'update-address',
  ACCOUNT_SETTINGS_CHANGE_EMAIL: 'change-email',
  ACCOUNT_SETTINGS_PASSWORD: 'password',
  ACCOUNT_SETTINGS_PERSONAL_IMAGE: 'personal-image',
  ACCOUNT_SETTINGS_DATA_USAGE_CONSENTS: 'data-usage-consents',
  ACCOUNT_SETTINGS_USER_PREFERENCES: 'user-preferences',
  ACCOUNT_SETTINGS_EMAIL_NOTIFICATIONS: 'email-notifications',

  COMMUNITY_SETTINGS: 'settings',
  COMMUNITY_SETTINGS_DETAILS: 'community-details',
  COMMUNITY_SETTINGS_ADDRESS: 'community-address',
  COMMUNITY_SETTINGS_IMAGE: 'community-image',
  COMMUNITY_SETTINGS_CUSTOM_INFO: 'community-custom-info',
  COMMUNITY_CAR_PARK_SETTINGS: 'car-park-settings',
  COMMUNITY_CAR_PARK_SETTINGS_EDIT: 'edit/:carParkId',
  COMMUNITY_SETTINGS_PREDEFINED_ADDRESSES: 'community-predefined-addresses',
  COMMUNITY_SETTINGS_PREAPPROVED_SETTINGS: 'community-preapproved-settings',
  COMMUNITY_SETTINGS_PREDEFINED_SHIFTS: 'predefined-shifts',

  COMMUNITY_DASHBOARD: 'dashboard',
  COMMUNITY_MESSAGES: 'messages',
  COMMUNITY_MESSAGES_USER: ':userId',
  COMMUNITY_MAP: 'map',

  COMMUNITY_PEOPLE: 'people',
  COMMUNITY_PEOPLE_PROFILE: ':userId',
  COMMUNITY_PEOPLE_PROFILE_PERMISSIONS: ':userId/permissions',

  COMMUNITY_BOOKINGS: 'bookings',

  COMMUNITY_REPORTS: 'reports',

  GAMIFICATION: 'gamification',
  GAMIFICATION_POINTS_SYSTEM: 'points-system',
  GAMIFICATION_REWARDS: 'rewards',
  GAMIFICATION_EARNED_REWARDS: 'earned-rewards',
  GAMIFICATION_CAMPAIGNS: 'campaigns',
  GAMIFICATION_CAMPAIGNS_CREATE_NEW: 'create-new',
  GAMIFICATION_CAMPAIGNS_EDIT: 'edit/:campaignId',

  SUSTAINABILITY: 'sustainability',
  SUSTAINABILITY_SURVEY: ':surveyId',

  EVENTS: 'events',
};

export default appUri;
