import { useMemo, useCallback, useContext, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import {
  Form,
  validators,
  validationRegexes,
  FormField,
  DataState,
  FormRef,
} from '@faxi/web-form';
import {
  Heading,
  getColor,
  useCallbackRef,
  useFormButtons,
  useUtilities,
  Button,
} from '@faxi/web-component-library';
import { AppContext, UserContext } from 'store';
import { useCallbackAsync, useEffectOnceWhen } from 'hooks';
import { snackBarSuccessMessage } from 'utils';
import { ConsentsPayload } from 'models';
import { apiConsents } from 'modules';
import Each from 'helpers/Each';
import specific from 'validation/validators/specific';

import {
  Icon,
  InputField,
  FormActions,
  ImageDropzoneField,
  PageLayout,
  Containers,
  CheckboxInfoField,
  RadioGroupField,
} from 'components';
import { useNavigate } from 'react-router-dom';
import { appUri } from '../../../config';

const UpdateProfileForm: FC = () => {
  const { t } = useTranslation();

  const {
    user,
    userReady,
    onboardingStep,
    setOnboardingStep,
    updateUser,
    getConsents,
  } = useContext(UserContext);

  const [FormButtons, setLoading] = useFormButtons({
    submitLabel: onboardingStep >= 3 ? t('Save') : t('Next'),
    cancelLabel: t('cancel'),
    loadingOverlaySelector: '.kinto-page',
  });

  const { platform } = useContext(AppContext);

  const [consentsPayload, setConsentsPayload] = useState<ConsentsPayload>();

  const { showSnackBar } = useUtilities();
  const navigate = useNavigate();

  const [form, formRef] = useCallbackRef<FormRef>();

  const initialData = useMemo(() => {
    const obj = {};

    consentsPayload?.consents.forEach(({ id, value }) => {
      Object.assign(obj, { [id]: value });
    });

    return {
      ...obj,
      profilePic: user?.image_url,
      firstName: user?.firstname,
      lastName: user?.name,
      mobileNumber: user?.phone,
      gender: user?.gender,
    };
  }, [consentsPayload, user]);

  const validations = useMemo(
    () => ({
      firstName: [
        validators.general.required(
          t('validation-field_is_required', {
            fieldname: t('register_fname_hint'),
          })
        ),
        validators.general.regex(
          validationRegexes.name,
          t('validation-field_name_first_last', {
            fieldname: t('register_fname_hint').toLowerCase(),
          })
        ),
        validators.general.maxLength(
          20,
          t('validation-field_validation_max_length', {
            fieldname: t('register_fname_hint').toLowerCase(),
            number: '20',
          })
        ),
      ],
      lastName: [
        validators.general.required(
          t('validation-field_is_required', {
            fieldname: t('register_lname_hint'),
          })
        ),
        validators.general.regex(
          validationRegexes.name,
          t('validation-field_name_first_last', {
            fieldname: t('register_lname_hint').toLowerCase(),
          })
        ),
        validators.general.maxLength(
          50,
          t('validation-field_validation_max_length', {
            fieldname: t('register_lname_hint').toLowerCase(),
            number: '50',
          })
        ),
      ],
      mobileNumber: [
        validators.phone.regex(
          specific.PHONE_NUMBER,
          t('validation-field_phone_number')
        ),
      ],
    }),
    [t]
  );

  const [loadConsents] = useCallbackAsync({
    callback: async () => {
      setConsentsPayload(await getConsents());
    },
    condition: userReady,
    showSpinner: true,
    spinnerParent: '.kinto-page',
  });

  const handleSubmit = useCallback(
    async (data: DataState) => {
      if (!user) {
        return;
      }

      if (!form?.isFormChanged(['profilePic'])) {
        setOnboardingStep(2);
        navigate(`/account-settings/${appUri.ACCOUNT_SETTINGS_UPDATE_ADDRESS}`);
        return;
      }

      const {
        lastName,
        firstName,
        mobileNumber,
        gender,
        profilePic,
        ...consentData
      } = data;

      const transformedData = {
        name: lastName,
        firstname: firstName,
        phone: mobileNumber,
        gender: gender,
      };

      try {
        setLoading(true);

        const formattedData: any[] = [];

        Object.keys(consentData).forEach((key) => {
          formattedData.push({ key: key, val: consentData[key] ? 'Y' : 'N' });
        });

        Promise.all([
          await updateUser(user.id, transformedData, 'PUT'),

          await apiConsents.uploadConsents(
            user!.id,
            formattedData,
            platform!.id
          ),

          await loadConsents(),
        ]);

        if (onboardingStep < 3) {
          setOnboardingStep(2);
          navigate(
            `/account-settings/${appUri.ACCOUNT_SETTINGS_UPDATE_ADDRESS}`
          );
        }

        showSnackBar({
          actionButtonText: t('dismiss'),
          text: t('personal_details_successfully_updated'),
          variant: 'success',
        });
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    },
    [
      user,
      form,
      setLoading,
      updateUser,
      platform,
      loadConsents,
      onboardingStep,
      showSnackBar,
      t,
      setOnboardingStep,
      navigate,
    ]
  );

  const [uploadUserProfileImage] = useCallbackAsync({
    showSpinner: true,
    callback: async (image: File) => {
      if (!user) return;

      await updateUser(user.id, {
        file: 'PROFILEPIC',
        image,
      });

      const imageURL = URL.createObjectURL(image);

      snackBarSuccessMessage(t('my_account_notification_image_uploaded'));

      return imageURL;
    },
  });

  const [deleteUserProfileImage] = useCallbackAsync({
    callback: async () => {
      if (!user) return;

      await updateUser(
        user.id,
        {
          file: 'PROFILEPIC',
        },
        'DELETE'
      );
    },
  });

  useEffectOnceWhen(() => {
    loadConsents();
  }, userReady);

  return (
    <PageLayout className="kinto-page">
      <div className="kinto-page__header__actions">
        <Button
          className="kinto-page__header__actions__close-btn"
          variant="ghost"
          icon={<Icon name="xmark" />}
          onClick={() => navigate(`/home`)}
        />
      </div>

      <Heading
        level="1"
        color={getColor('--PRIMARY_1_1')}
        className="kinto-page__heading"
      >
        {t('account-personal_details_update_profile')}
      </Heading>
      <Containers.SettingsForm className="update-profile-form">
        <Form
          id="personal_info_form"
          initialData={initialData}
          onSubmit={handleSubmit}
          ref={formRef}
        >
          <fieldset className="form__fields">
            <legend data-hidden hidden>
              {t('settings_personal_title')}
            </legend>
            <FormField
              name="profilePic"
              circularCrop
              component={ImageDropzoneField}
              title={t('my_account-title_profile_image')}
              placeholder={<Icon name="user-circle" />}
              uploadRequest={uploadUserProfileImage}
              deleteRequest={deleteUserProfileImage}
              accept={{
                'image/png': ['.png'],
                'image/jpg': ['.jpg'],
                'image/jpeg': ['.jpeg'],
              }}
            />
            <FormField
              id="person_first_name"
              name="firstName"
              autoComplete="on"
              component={InputField}
              placeholder={t('register_fname_hint')}
              validate={validations.firstName}
              required
              requiredLabel={t('global-input_field_required_label')}
            />
            <FormField
              id="person_last_name"
              name="lastName"
              autoComplete="on"
              component={InputField}
              placeholder={t('register_lname_hint')}
              validate={validations.lastName}
              required
              requiredLabel={t('global-input_field_required_label')}
            />

            <FormField
              id="person_private_phone"
              name="mobileNumber"
              component={InputField}
              autoComplete="on"
              placeholder={t('dashboard_details_phone_number')}
              validate={validations.mobileNumber}
            />

            <FormField
              component={RadioGroupField}
              name="gender"
              orientation="column"
              label={t('onboarding-title_what_is_your_gender')}
              options={[
                {
                  label: t('plan_commute_gender_female'),
                  value: 'F',
                },
                {
                  label: t('plan_commute_gender_male'),
                  value: 'M',
                },
                {
                  label: t('plan_commute_gender_other'),
                  value: 'O',
                },
              ]}
            />
          </fieldset>

          <fieldset className="data-usage-consents">
            <legend data-hidden hidden>
              {t('data_usage_consent')}
            </legend>
            {consentsPayload?.consents && (
              <Each
                of={consentsPayload?.consents}
                render={({ id, titleKey, subtitleKey }) => (
                  <FormField
                    className="consents__box"
                    id={id}
                    key={id}
                    name={id}
                    component={CheckboxInfoField}
                    label={t(titleKey)}
                    labelPosition="left"
                    description={parse(t(subtitleKey))}
                  />
                )}
              />
            )}
          </fieldset>
          <FormActions className="form__actions">
            <FormButtons.Submit
              id="submit_personal_info"
              disabled={
                (onboardingStep >= 3 && !form?.isFormChanged(['profilePic'])) ||
                !form?.syncFormValid
              }
            />
          </FormActions>
        </Form>
      </Containers.SettingsForm>
    </PageLayout>
  );
};

export default UpdateProfileForm;
