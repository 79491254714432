import { FC, useContext } from 'react';
import { Spinner, getColor } from '@faxi/web-component-library';
import classNames from 'classnames';

import { UserContext } from 'store';

import * as CommunityElementStyled from './CommunityElement.styles';

const CommunityElement: FC<{ className?: string }> = ({ className }) => {
  const { community } = useContext(UserContext);

  return (
    <CommunityElementStyled.CommunityElement
      className={classNames('kinto-community-element', className)}
    >
      {!community ? (
        <Spinner size={12} color={getColor('--PRIMARY_1_1')} />
      ) : (
        community.name
      )}
    </CommunityElementStyled.CommunityElement>
  );
};

export default CommunityElement;
