import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectOption } from '@faxi/web-component-library';

import { ReportsContext } from 'store';

type LeaderboardOptions = {
  pointsActive: boolean;
};

const useOptions = (props: LeaderboardOptions) => {
  const { pointsActive } = props;
  const { t } = useTranslation();

  const { depotFilters } = useContext(ReportsContext);

  const pointsOptions = useMemo<SelectOption[]>(
    () =>
      [
        {
          label: t('total'),
          value: 'point',
        },
      ].concat(
        (depotFilters || [])?.map((filter) => ({
          label: filter.data.name,
          value: `${filter.id}`,
        }))
      ),
    [depotFilters, t]
  );

  const modeOptions = useMemo<SelectOption[]>(
    () => [
      {
        label: t('total'),
        value: 'all',
      },
      {
        label: t('cp_map_driving'),
        value: 'driving',
      },
      {
        label: t('cp_map_walking'),
        value: 'walking',
      },
      {
        label: t('cp_map_cycling'),
        value: 'cycling',
      },
      {
        label: t('A_passenger'),
        value: 'passengers',
      },
    ],
    [t]
  );

  const finalOptions = useMemo(
    () => (pointsActive ? pointsOptions : modeOptions),
    [modeOptions, pointsActive, pointsOptions]
  );

  return { pointsOptions, modeOptions, finalOptions };
};

export default useOptions;
